@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,300;0,400;0,500;0,600;1,300;1,400;1,500;1,600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&family=Roboto+Mono:ital,wght@0,300;0,400;0,500;0,600;1,300;1,400;1,500;1,600&display=swap");
:root {
  --green:#3e8668;
  --lightgreen:#669280;
  --red: #863e3e;
}

html {
  height: 100%;
  min-height: 100%;
  /* make sure it is at least as tall as the viewport */
  position: relative;
  margin: 0;
}

body {
  padding: 0;
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  background-color: #0a0e0d;
  height: 100%;
}

div.grecaptcha-badge {
  display: none !important;
}

.main {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.MuiCircularProgress-root {
  color: var(--green);
}

.homebg {
  position: relative;
  height: 100%;
  width: 100%;
  background-image: url("./assets/images/home.jpg");
  background-size: cover;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-transform-origin: middle left;
          transform-origin: middle left;
}

.homebg.minimize-left {
  transform: perspective(460px) rotateY(8deg) translateX(-200px) scale(0.5);
  -webkit-transform: perspective(460px) rotateY(8deg) translateX(-200px) scale(0.5);
  -moz-transform: perspective(460px) rotateY(8deg) translateX(-200px) scale(0.5);
  -ms-transform: perspective(460px) rotateY(8deg) translateX(-200px) scale(0.5);
  -o-transform: perspective(460px) rotateY(8deg) translateX(-200px) scale(0.5);
  opacity: 0.4 !important;
}

.homebg::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.65);
}

.homeCont {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 100%;
}

.homeCont_wrapper {
  color: white;
}

.homeCont_wrapper h1 {
  text-align: left;
  line-height: 1.4;
  font-size: 50px;
  font-weight: 700;
  margin-bottom: 5px;
}

.homeCont_wrapper h3 {
  text-align: left;
  line-height: 1.2;
  font-family: 'Roboto Mono', monospace;
  font-weight: 400;
  letter-spacing: -1px;
  font-size: 14px;
  color: #797979;
}

.homeCont_wrapper .homeCont_descr {
  margin-top: 20px;
}

.homeCont_wrapper .homeCont_descr__highlight {
  display: block;
  font-size: 25px;
  margin-bottom: 0;
  font-weight: 600;
  margin-bottom: 10px;
}

.homeCont_wrapper .homeCont_descr__contact {
  display: block;
  font-size: 18px;
  margin-bottom: 0;
}

.btnToggForm {
  color: var(--green);
  cursor: pointer;
}

.btnToggForm svg {
  width: 20px;
}

.sidebar {
  position: absolute;
  height: 100%;
  width: 60px;
  background: #132320;
  top: 0;
  left: 0;
}

.close-right-side {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.contactForm-Wrapper {
  background: #f3f3f3;
  position: absolute;
  overflow: auto;
  z-index: 10;
  top: 0;
  right: 0;
  width: 60vw;
  height: 100%;
  min-height: 100%;
  min-height: 100%;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.contactForm-Wrapper.hide-right {
  right: -100%;
}

.contactForm-Wrapper .closeContact {
  position: relative;
  float: right;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 45px;
  height: 40px;
  background: #dadada;
}

.contactForm-Wrapper .contactForm-Inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 100%;
  width: 100%;
}

.contactForm-Wrapper .contactForm-Inner .contactForm {
  padding: 40px;
  width: 100%;
  max-width: 675px;
  display: block;
  height: auto;
}

.contactForm-Wrapper .contactForm-Inner .contactForm .formSubmit {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.contactForm-Wrapper .contactForm-Inner .contactForm .form-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.contactForm-Wrapper .contactForm-Inner .contactForm .form-group .MuiFormControl-root {
  width: 100%;
}

.contactForm-Wrapper .contactForm-Inner .contactForm .formSubmit button {
  border: 1px solid #0c5436;
  background-color: #3e8668;
  color: white;
  font-size: 16px;
  font-weight: 600;
}

.contactForm-Wrapper .contactForm-Inner .contactForm .formSubmit button:active, .contactForm-Wrapper .contactForm-Inner .contactForm .formSubmit button:focus {
  outline: none;
  border-color: var(--green);
}

.contactForm-Wrapper .contactForm-Inner .contactForm .contactForm-Result {
  display: block;
  font-size: 18px;
  margin-bottom: 0;
  font-weight: 600;
  margin-bottom: 10px;
  text-align: center;
}

.contactForm-Wrapper .contactForm-Inner .contactForm .contactForm-Result__success {
  color: var(--green);
}

.contactForm-Wrapper .contactForm-Inner .contactForm .contactForm-Result__error {
  color: var(--red);
}

.contactForm-Wrapper .contactForm-Inner .contactForm .contactForm-Result__error a {
  color: black;
  text-decoration: none;
}

label.MuiFormLabel-root.Mui-focused {
  color: var(--green);
}

div.MuiFilledInput-underline:after {
  border-bottom-color: var(--green);
}

.footer {
  position: absolute;
  bottom: 0;
  padding: 0 20px;
  width: 100%;
  border-top: 1px solid #3e8668;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.footer .footer__socials ol {
  list-style-type: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0;
  margin: 0;
  color: #c2c2c2;
}

.footer .footer__socials ol a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: #c2c2c2;
  text-decoration: none;
  width: 100%;
  height: 100%;
}

.footer .footer__socials ol li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  transition: background-color 0.3s ease;
  margin-right: 20px;
  font-size: 14px;
  cursor: pointer;
  width: 45px;
  height: 40px;
  -webkit-transition: background-color 0.3s ease;
  -moz-transition: background-color 0.3s ease;
  -ms-transition: background-color 0.3s ease;
  -o-transition: background-color 0.3s ease;
}

.footer .footer__socials ol li:hover {
  transition: background-color 0.3s ease;
  background-color: rgba(255, 255, 255, 0.2);
  -webkit-transition: background-color 0.3s ease;
  -moz-transition: background-color 0.3s ease;
  -ms-transition: background-color 0.3s ease;
  -o-transition: background-color 0.3s ease;
}

.footer .footer__socials ol li svg {
  width: 20px;
}

.footer .footer__copyright {
  font-size: 11px;
  color: #c2c2c2;
}

@media only screen and (max-width: 768px) {
  .contactForm-Wrapper {
    width: 100vw;
  }
  .contactForm-Wrapper .contactForm-Inner {
    display: block;
    height: 100%;
    width: 100%;
  }
  .contactForm-Wrapper .contactForm-Inner .contactForm {
    margin-top: 40px;
    padding: 40px 20px;
  }
  .footer {
    padding: 0 15px;
  }
  .footer .footer__socials ol li {
    margin-right: 5px;
  }
}
